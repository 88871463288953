import React, { useState, useCallback } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonFooter, IonList, IonItem, IonLabel, useIonRouter } from '@ionic/react';
import { useCartManager } from '../contexts/CartManagerContext';
import { useDeliveryPickupManager } from '../contexts/DeliveryPickupManagerContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import CheckoutService, { DeliveryInfo as CheckoutDeliveryInfo } from '../services/CheckoutService';
import './CheckoutView.css';

// Replace with your actual Stripe publishable key
const stripePromise = loadStripe('pk_test_51O0Xl1JLWaPysyNYlg54rt9cjdxEsTjHsQBvXsLV2uwVj2KY3LOlhpLxNPY19I7mrdB8fKQknePb9m2A9fnqXYo600iDre3vlf');

const checkoutService = new CheckoutService();

interface CheckoutViewProps {
  onDismiss: (data?: any, role?: string) => void;
}

interface ComponentDeliveryInfo {
  address: {
    formattedAddress: string;
    // Add other address fields as needed
  };
  instructions?: string;
}

const CheckoutView: React.FC<CheckoutViewProps> = ({ onDismiss }) => {
  const { cart, getCartTotal, clearCart } = useCartManager();
  const { orderType, scheduledDate, deliveryInfo } = useDeliveryPickupManager();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [orderId, setOrderId] = useState<string | null>(null);
  const router = useIonRouter();

  const taxRate = 0.05; // 5% tax rate
  const subtotal = getCartTotal();
  const tax = subtotal * taxRate;
  const total = subtotal + tax;

  const formatCurrency = (amount: number) => {
    return `AED ${amount.toFixed(2)}`;
  };

  const formatSchedule = (date: string | null) => {
    if (!date) return 'Now';
    const dateObj = new Date(date);
    return `${dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} at ${dateObj.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
  };

  const convertDeliveryInfo = (info: ComponentDeliveryInfo | null): CheckoutDeliveryInfo | undefined => {
    if (!info) return undefined;
    return {
      address: info.address.formattedAddress,
      instructions: info.instructions
    };
  };

  const handlePlaceOrder = async () => {
    try {
      const response = await checkoutService.createPaymentIntent(
        cart,
        orderType === 'delivery',
        convertDeliveryInfo(deliveryInfo),
        scheduledDate ?? undefined
      );
      setClientSecret(response.paymentIntent);
      setOrderId(response.orderId);
    } catch (error) {
      console.error('Error creating PaymentIntent:', error);
      // Handle error (show message to user, etc.)
    }
  };

  const handlePaymentSuccess = useCallback(async (paymentIntentId: string) => {  
    if (orderId) {
      try {
        const completedOrderId = await checkoutService.completePayment(orderId, paymentIntentId);
        clearCart();
        router.push(`/order/${completedOrderId}`);
      } catch (error) {
        console.error('Error completing payment:', error);
        // Handle error (show message to user, etc.)
      }
    } else {
      console.error('Missing orderId');
      // Handle error (show message to user, etc.)
    }
  }, [orderId, router, clearCart]);

  return (
    <IonPage className="checkout-view">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Checkout</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss(null, 'cancel')}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="order-schedule">
          {orderType === 'pickup' ? 'Schedule a Pickup' : 'Schedule a Delivery'} {formatSchedule(scheduledDate)}
        </div>
        <IonList>
          {cart.map((item) => (
            <IonItem key={item.menuItem.id}>
              <IonLabel>
                {item.menuItem.name} x {item.count}
              </IonLabel>
              <IonLabel slot="end" className="ion-text-right">
                {formatCurrency(Number(item.menuItem.price) * item.count)}
              </IonLabel>
            </IonItem>
          ))}
          <IonItem>
            <IonLabel>Subtotal</IonLabel>
            <IonLabel slot="end" className="ion-text-right">
              {formatCurrency(subtotal)}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Tax</IonLabel>
            <IonLabel slot="end" className="ion-text-right">
              {formatCurrency(tax)}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <strong>Total</strong>
            </IonLabel>
            <IonLabel slot="end" className="ion-text-right">
              <strong>{formatCurrency(total)}</strong>
            </IonLabel>
          </IonItem>
        </IonList>
        {clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm onPaymentSuccess={handlePaymentSuccess} />
          </Elements>
        )}
      </IonContent>
      <IonFooter>
        {!clientSecret ? (
          <IonButton expand="block" className="place-order-button" onClick={handlePlaceOrder}>
            Place Order
          </IonButton>
        ) : null}
        <p className="terms-text">
          By placing an order, you agree to our terms and policies.
        </p>
      </IonFooter>
    </IonPage>
  );
};

export default CheckoutView;