import React, { createContext, useState, useContext } from 'react';

// Define types
type OrderType = 'delivery' | 'pickup';
type OrderSchedule = 'immediate' | { scheduled: Date };

interface DeliveryAddress {
  id: string;
  formattedAddress: string;
  // Add other necessary fields
}

interface DeliveryInfo {
  address: DeliveryAddress;
  instructions?: string;
}

interface DeliveryPickupManagerContextType {
  orderType: OrderType;
  orderSchedule: OrderSchedule;
  pickupAddresses: string[];
  deliveryAddress: DeliveryAddress | null;
  deliveryInstructions: string | null;
  deliveryAddresses: DeliveryAddress[];
  isDelivery: boolean;
  isScheduled: boolean;
  isImmediate: boolean;
  deliveryInfo: DeliveryInfo | null;
  scheduledDate: string | null;
  updateDeliveryAddress: (newAddress: DeliveryAddress | null) => void;
  addPickupAddress: (newAddress: string) => void;
  updateOrderType: (newOrderType: OrderType) => void;
  updateOrderSchedule: (newSchedule: OrderSchedule) => void;
}

const DeliveryPickupManagerContext = createContext<DeliveryPickupManagerContextType | undefined>(undefined);

export const DeliveryPickupManagerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [orderType, setOrderType] = useState<OrderType>('pickup');
  const [orderSchedule, setOrderSchedule] = useState<OrderSchedule>('immediate');
  const [pickupAddresses, setPickupAddresses] = useState<string[]>(['123 Main St, City, Country']);
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress | null>(null);
  const [deliveryInstructions, setDeliveryInstructions] = useState<string | null>(null);
  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>([
    { id: '1', formattedAddress: '456 Elm St, City, Country' },
    { id: '2', formattedAddress: '789 Oak St, City, Country' },
  ]);

  const isDelivery = orderType === 'delivery';
  const isScheduled = orderSchedule !== 'immediate';
  const isImmediate = orderSchedule === 'immediate';

  const deliveryInfo: DeliveryInfo | null = isDelivery && deliveryAddress
    ? { address: deliveryAddress, instructions: deliveryInstructions || undefined }
    : null;

  const scheduledDate = isScheduled && typeof orderSchedule !== 'string'
    ? orderSchedule.scheduled.toISOString()
    : null;

  const updateDeliveryAddress = (newAddress: DeliveryAddress | null) => {
    setDeliveryAddress(newAddress);
    if (!newAddress && deliveryAddresses.length > 0) {
      setDeliveryAddress(deliveryAddresses[0]);
    }
  };

  const addPickupAddress = (newAddress: string) => {
    setPickupAddresses([...pickupAddresses, newAddress]);
  };

  const updateOrderType = (newOrderType: OrderType) => {
    setOrderType(newOrderType);
    if (newOrderType === 'pickup') {
      setDeliveryAddress(null);
      setDeliveryInstructions(null);
    }
  };

  const updateOrderSchedule = (newSchedule: OrderSchedule) => {
    setOrderSchedule(newSchedule);
  };

  const value = {
    orderType,
    orderSchedule,
    pickupAddresses,
    deliveryAddress,
    deliveryInstructions,
    deliveryAddresses,
    isDelivery,
    isScheduled,
    isImmediate,
    deliveryInfo,
    scheduledDate,
    updateDeliveryAddress,
    addPickupAddress,
    updateOrderType,
    updateOrderSchedule,
  };

  return (
    <DeliveryPickupManagerContext.Provider value={value}>
      {children}
    </DeliveryPickupManagerContext.Provider>
  );
};

export const useDeliveryPickupManager = () => {
  const context = useContext(DeliveryPickupManagerContext);
  if (context === undefined) {
    throw new Error('useDeliveryPickupManager must be used within a DeliveryPickupManagerProvider');
  }
  return context;
};