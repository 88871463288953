import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import Button from '../components/Button';

const Order: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Order</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Order</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className="ion-padding">
                    <h2>Place your order</h2>
                    <p>Fill in your details and confirm your order</p>
                    <Button onClick={() => console.log('Confirm Order clicked')}>
                        Confirm Order
                    </Button>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Order;
