import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { menu, cart, person } from 'ionicons/icons';
import Menu from './pages/Menu';
import Order from './pages/Order';
import Account from './pages/Account';
import CartView from './pages/CartView';
import CheckoutView from './pages/CheckoutView';
import LoginView from './pages/LoginView';
import OTPVerificationView from './pages/OTPVerificationView';
import InstallPWA from './components/InstallPWA';
import { CartManagerProvider } from './contexts/CartManagerContext';
import { AuthProvider } from './contexts/AuthContext';
import { DeliveryPickupManagerProvider } from './contexts/DeliveryPickupManagerContext';

const App: React.FC = () => (
  <DeliveryPickupManagerProvider>
    <CartManagerProvider>
      <AuthProvider>
        <IonApp>
          <IonReactRouter>
            <IonTabs>
              <IonRouterOutlet>
                <Route exact path="/menu">
                  <Menu />
                </Route>
                <Route exact path="/order">
                  <Order />
                </Route>
                <Route exact path="/account">
                  <Account />
                </Route>
                <Route exact path="/">
                  <Redirect to="/menu" />
                </Route>
                <Route path="/cart" component={CartView} exact={true} />
                <Route path="/login" component={LoginView} exact={true} />
                <Route path="/verify-otp" component={OTPVerificationView} exact={true} />
                <Route path="/checkout" component={CheckoutView} exact={true} />
              </IonRouterOutlet>
              <IonTabBar slot="bottom">
                <IonTabButton tab="menu" href="/menu" selected={true}>
                  <IonIcon icon={menu} />
                  <IonLabel>Menu</IonLabel>
                </IonTabButton>
                <IonTabButton tab="order" href="/order">
                  <IonIcon icon={cart} />
                  <IonLabel>Order</IonLabel>
                </IonTabButton>
                <IonTabButton tab="account" href="/account">
                  <IonIcon icon={person} />
                  <IonLabel>Account</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
            <InstallPWA />
          </IonReactRouter>
        </IonApp>
      </AuthProvider>
    </CartManagerProvider>
  </DeliveryPickupManagerProvider>
);

export default App;