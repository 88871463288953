import React, { createContext, useState, useContext, useEffect } from 'react';
import { Preferences } from '@capacitor/preferences';
import { authService, User } from '../services/AuthService'; // Import the AuthService

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (email: string) => Promise<void>;
  logout: () => Promise<void>;
  verifyOTP: (otp: string) => Promise<void>;
  updateUserName: (name: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      const { value: token } = await Preferences.get({ key: 'authToken' });
      if (token) {
        try {
          const userData = await authService.fetchUserInfo();
          setUser(userData);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Error verifying authentication:', error);
          await logout(); // Logout if token is invalid
        }
      }
    };
    checkAuth();
  }, []);

  const login = async (email: string) => {
    try {
      await authService.login(email);
      // Note: We don't set isAuthenticated here because the user still needs to verify OTP
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const verifyOTP = async (otp: string) => {
    try {
      await authService.verifyOTP(otp);
      setIsAuthenticated(true);
      const userData = await authService.fetchUserInfo();
      setUser(userData);
    } catch (error) {
      console.error('OTP verification error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await authService.logout();
      setIsAuthenticated(false);
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  };

  const updateUserName = async (name: string) => {
    try {
      const response = await authService.updateUserName(name);
      if (response.status) {
        setUser(prevUser => prevUser ? { ...prevUser, name } : null);
      } else {
        throw new Error(response.message || 'Failed to update name');
      }
    } catch (error) {
      console.error('Error updating user name:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, verifyOTP, updateUserName }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};