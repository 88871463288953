import axios from 'axios';

// TODO: Externalize these constants to a config file for build-time configuration

const BASE_URL = 'https://test.api.chickybells.dubuyeats.com';
const RESTAURANT_ID = 'chickybells.dev';
const TIME_ZONE = 'Asia/Dubai'; // Assuming UAE time zone

export interface MenuItem {
    id: string;
    name: string;
    description: string;
    currency: string;
    price: number | string;
    image_url: string | null | undefined;
    status: string;
}

export interface MenuCategory {
    id: string;
    name: string;
    items: MenuItem[];
}

const formatDate = (date: Date): string => {
    return date.toISOString().split('.')[0];
};

export const fetchMenuItems = async (): Promise<MenuCategory[]> => {
    try {
        const currentDate = new Date();
        // Adjust the date to the specified time zone
        const uaeDate = new Date(currentDate.toLocaleString('en-US', { timeZone: TIME_ZONE }));
        const requestTime = formatDate(uaeDate);

        const url = `${BASE_URL}/${RESTAURANT_ID}/menu`;
        const response = await axios.get(url, {
            params: { request_time: requestTime },
            headers: { 'Content-Type': 'application/json' }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching menu items:', error);
        throw error;
    }
};