import React from 'react';
import { IonContent, IonPage, IonButton, IonText, IonImg, IonIcon } from '@ionic/react';
import { arrowBack, closeOutline } from 'ionicons/icons';
import { useAuth } from '../contexts/AuthContext';
import OTPInput from '../components/OTPInput';
import './OTPVerificationView.css';

interface OTPVerificationViewProps {
  onDismiss: (data?: any, role?: string) => void;
  email: string;
}

const OTPVerificationView: React.FC<OTPVerificationViewProps> = ({ onDismiss, email }) => {
  const { verifyOTP } = useAuth();

  const handleOTPComplete = async (otp: string) => {
    try {
      await verifyOTP(otp);
      onDismiss(undefined, 'confirm');
    } catch (error) {
      console.error('OTP verification error:', error);
    }
  };

  return (
    <IonPage className="otp-verification-view">
      <IonContent className="ion-padding">
        <div className="header">
          <IonIcon icon={arrowBack} className="back-button" onClick={() => onDismiss(null, 'back')} />
          <IonIcon icon={closeOutline} className="close-button" onClick={() => onDismiss(null, 'cancel')} />
        </div>
        <IonImg src="/assets/chicky-bells-logo.png" alt="Chicky Bells Logo" className="logo" />
        <h1>Verify your email</h1>
        <p className="instruction">
          Please enter the verification code we sent to <span className="email">{email}</span> to complete the verification process.
        </p>
        <OTPInput length={4} onComplete={handleOTPComplete} />
        <IonButton expand="block" className="verify-button">Verify</IonButton>
        <IonButton fill="clear" className="resend-button">Resend the code</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default OTPVerificationView;