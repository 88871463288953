import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { MenuItem } from '../services/menuApiService';

export interface CartItem {
    menuItem: MenuItem;
    count: number;
}

export interface CartManager {
    cart: CartItem[];
    addToCart: (item: CartItem) => void;
    removeFromCart: (itemId: string) => void;
    updateItemQuantity: (itemId: string, newCount: number) => void;
    clearCart: () => void;
    getCartTotal: () => number;
}

const CartManagerContext = createContext<CartManager | undefined>(undefined);

export const useCartManager = () => {
    const context = useContext(CartManagerContext);
    if (context === undefined) {
        throw new Error('useCartManager must be used within a CartManagerProvider');
    }
    return context;
};

interface CartManagerProviderProps {
    children: ReactNode;
}

const CART_STORAGE_KEY = 'app_cart_data';

export const CartManagerProvider: React.FC<CartManagerProviderProps> = ({ children }) => {
    const [cart, setCart] = useState<CartItem[]>(() => {
        // Load cart from local storage on initial render
        const savedCart = localStorage.getItem(CART_STORAGE_KEY);
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        // Save cart to local storage whenever it changes
        localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart));
    }, [cart]);

    const addToCart = (item: CartItem) => {
        setCart(prevCart => {
            const existingItem = prevCart.find(cartItem => cartItem.menuItem.id === item.menuItem.id);
            if (existingItem) {
                return prevCart.map(cartItem =>
                    cartItem.menuItem.id === item.menuItem.id
                        ? { ...cartItem, count: cartItem.count + item.count }
                        : cartItem
                );
            } else {
                return [...prevCart, item];
            }
        });
    };

    const removeFromCart = (itemId: string) => {
        setCart(prevCart => prevCart.filter(item => item.menuItem.id !== itemId));
    };

    const updateItemQuantity = (itemId: string, newCount: number) => {
        setCart(prevCart =>
            prevCart.map(item =>
                item.menuItem.id === itemId ? { ...item, count: newCount } : item
            ).filter(item => item.count > 0) // Remove items with count 0
        );
    };

    const clearCart = () => {
        setCart([]);
    };

    const getCartTotal = () => {
        return cart.reduce((total, item) => {
            const price = typeof item.menuItem.price === 'string' ? parseFloat(item.menuItem.price) : item.menuItem.price;
            return total + price * item.count;
        }, 0);
    };

    const value: CartManager = {
        cart,
        addToCart,
        removeFromCart,
        updateItemQuantity,
        clearCart,
        getCartTotal,
    };

    return (
        <CartManagerContext.Provider value={value}>
            {children}
        </CartManagerContext.Provider>
    );
};

export default CartManagerContext;