import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import Button from '../components/Button';

const Account: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Account</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Account</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className="ion-padding">
                    <h2>Account</h2>
                    <p>Update your account details and preferences</p>
                    <Button onClick={() => console.log('Update Account clicked')}>
                        Update Account
                    </Button>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Account;