import React, { useState, useRef, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import './OTPInput.css';

interface OTPInputProps {
  length: number;
  onComplete: (otp: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, onComplete }) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
  const inputRefs = useRef<HTMLIonInputElement[]>([]);

  useEffect(() => {
    inputRefs.current[0]?.setFocus();
  }, []);

  const handleChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value.length === 1) {
      if (index < length - 1) {
        inputRefs.current[index + 1]?.setFocus();
      } else {
        inputRefs.current[index]?.getInputElement().then((input) => input?.blur());
        onComplete(newOtp.join(''));
      }
    } else if (value.length === 0 && index > 0) {
      inputRefs.current[index - 1]?.setFocus();
    }
  };

  return (
    <div className="otp-input-container">
      {otp.map((digit, index) => (
        <IonInput
          key={index}
          ref={(el: HTMLIonInputElement | null) => {
            if (el) inputRefs.current[index] = el;
          }}
          type="text"
          inputMode="numeric"
          maxlength={1}
          value={digit}
          onIonChange={(e: CustomEvent) => handleChange(index, e.detail.value || '')}
          className="otp-digit"
        />
      ))}
    </div>
  );
};

export default OTPInput;