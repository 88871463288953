import React, { useState, useEffect, useRef } from 'react';
import { IonImg, IonSkeletonText, IonIcon } from '@ionic/react';
import { fastFood } from 'ionicons/icons';

interface OptimizedImageProps {
  src: string;
  alt: string;
  className?: string;
}

const OptimizedImage: React.FC<OptimizedImageProps> = ({ src, alt, className }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const imgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShouldLoad(true);
          observer.disconnect();
        }
      },
      { rootMargin: '100px' }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (shouldLoad) {
      const img = new Image();
      img.src = src;
      img.onload = () => setImageLoaded(true);
      img.onerror = () => setError(true);
    }
  }, [shouldLoad, src]);

  if (error) {
    return (
      <div ref={imgRef} className={`${className} fallback-icon`}>
        <IonIcon icon={fastFood} style={{ width: '100%', height: '100%' }} />
      </div>
    );
  }

  return (
    <div ref={imgRef} className={className}>
      {!imageLoaded && <IonSkeletonText animated style={{ width: '100%', height: '100%', margin: 0 }} />}
      {shouldLoad && (
        <IonImg
          src={src}
          alt={alt}
          style={{ 
            display: imageLoaded ? 'block' : 'none',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      )}
    </div>
  );
};

export default OptimizedImage;