import React from 'react';
import { IonButton } from '@ionic/react';

interface ButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger';
  expand?: 'block' | 'full';
  size?: 'small' | 'default' | 'large';
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ 
  onClick, 
  children, 
  color = 'primary', 
  expand, 
  size = 'default',
  disabled = false,
  ...props 
}) => {
  return (
    <IonButton 
      onClick={onClick} 
      color={color}
      expand={expand}
      size={size}
      disabled={disabled}
      {...props}
    >
      {children}
    </IonButton>
  );
};

export default Button;