import React, { useState, useEffect, useRef } from 'react';
import { IonContent, IonPage, IonSearchbar, IonSpinner, IonModal, useIonViewDidEnter, IonButton, IonIcon } from '@ionic/react';
import { fetchMenuItems, MenuCategory, MenuItem } from '../services/menuApiService';
import OptimizedImage from '../components/OptimizedImage';
import { transformToCdnUrl } from '../utils/urlTransformer';
import MenuItemDetailsView from './MenuItemDetailsView';
import { useCartManager } from '../contexts/CartManagerContext';
import { cart as cartIcon } from 'ionicons/icons';
import CartView from './CartView';  
import './Menu.css';

const Menu: React.FC = () => {
    const [menuCategories, setMenuCategories] = useState<MenuCategory[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showCart, setShowCart] = useState(false); 
    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const { cart } = useCartManager();

    const totalCartItems = () => {
        return cart.reduce((total, item) => total + item.count, 0);
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const menuData = await fetchMenuItems();
                const transformedMenuData = menuData.map(category => ({
                    ...category,
                    items: category.items.map(item => ({
                        ...item,
                        image_url: item.image_url ? transformToCdnUrl(item.image_url) : null
                    }))
                }));
                setMenuCategories(transformedMenuData);
            } catch (err) {
                setError('Failed to load menu data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    useIonViewDidEnter(() => {
        if (contentRef.current) {
            contentRef.current.scrollEvents = true;
        }
    });
    
    const handleMenuItemClick = (item: MenuItem) => {
        setSelectedMenuItem(item);
        setShowModal(true);
    };

    const handleCloseDetails = () => {
        setShowModal(false);
        setSelectedMenuItem(null);
    };

    const handleViewCart = () => {
        setShowCart(true);
    };

    const handleCloseCart = () => {
        setShowCart(false);
    };

    return (
        <IonPage>
            <div className="fixed-header">
                <div className="header-container">
                    <div className="pickup-info">
                        <h1>Pickup Now</h1>
                        <p>@ Chicky Bells, Al Khalidiyah - W9, Abu Dhabi <span className="checkmark">✓</span></p>
                    </div>
                </div>
                <IonSearchbar placeholder="Search Menu" className="custom-searchbar"></IonSearchbar>
            </div>
            <IonContent 
                ref={contentRef}
                scrollEvents={true}
                fullscreen 
                className="menu-content"
            >
                {loading ? (
                    <div className="loading-container">
                        <IonSpinner />
                        <p>Loading menu...</p>
                    </div>
                ) : error ? (
                    <div className="error-container">
                        <p>{error}</p>
                        <button onClick={() => window.location.reload()}>Retry</button>
                    </div>
                ) : (
                    <div className="scrollable-content">
                        {menuCategories.map((category) => (
                            <div key={category.id} className="menu-category">
                                <h2 className="section-title">{category.name}</h2>
                                <div className="menu-items">
                                    {category.items.map((item) => (
                                        <MenuItemComponent 
                                            key={item.id} 
                                            item={item} 
                                            onClick={() => handleMenuItemClick(item)}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </IonContent>
            <IonModal isOpen={showModal} onDidDismiss={handleCloseDetails}>
                {selectedMenuItem && (
                    <MenuItemDetailsView 
                        menuItem={selectedMenuItem} 
                        onClose={handleCloseDetails} 
                    />
                )}
            </IonModal>
            {cart.length > 0 && (
                <div className="view-cart-button-container">
                <IonButton 
                    expand="block" 
                    onClick={handleViewCart}
                    className="view-cart-button"
                >
                    <IonIcon icon={cartIcon} slot="start" />
                    View Cart ({totalCartItems()})
                </IonButton>
            </div>
            )}
            <IonModal isOpen={showCart} onDidDismiss={handleCloseCart}>
                <CartView onClose={handleCloseCart} />
            </IonModal>
        </IonPage>
    );
};


interface MenuItemComponentProps {
    item: MenuItem;
    onClick: () => void;
}

const MenuItemComponent: React.FC<MenuItemComponentProps> = ({ item, onClick }) => {
    const formatPrice = (price: number | string): string => {
        if (typeof price === 'number') {
            return price.toFixed(2);
        }
        if (typeof price === 'string') {
            const numPrice = parseFloat(price);
            return isNaN(numPrice) ? price : numPrice.toFixed(2);
        }
        return '0.00';
    };

    return (
        <div className="menu-item" onClick={onClick}>
            <div className="item-content">
                <h2>{item.name}</h2>
                <h3>{item.currency} {formatPrice(item.price)}</h3>
                <p className="item-description">{item.description}</p>
            </div>
            {item.image_url && (
                <div className="item-image-wrapper">
                    <OptimizedImage 
                        src={item.image_url} 
                        alt={item.name}
                        className="item-image"
                    />
                </div>
            )}
        </div>
    );
};

export default Menu;