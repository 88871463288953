import { useAuth } from '../contexts/AuthContext';
import { Preferences } from '@capacitor/preferences';

const BASE_URL = 'https://test.api.chickybells.dubuyeats.com';
const RESTAURANT_ID = 'chickybells.dev';
const TIME_ZONE = 'Asia/Dubai'; // Assuming UAE time zone

export interface CartItem {
  menuItem: {
    id: string;
    name: string;
  };
  count: number;
}

export interface DeliveryInfo {
  address: string;
  instructions?: string;
}

export interface PaymentIntentResponse {
  paymentIntent: string;
  ephemeralKey: string;
  customer: string;
  publishableKey: string;
  paymentIntentId: string;
  orderId: string;
  orderSummary: OrderSummaryItem[];
}

export interface OrderSummaryItem {
  type: string;
  amount: string;
  description: string;
}

class CheckoutService {

  private get baseURL(): string {
    return BASE_URL;
  }

  private get restaurantId(): string {
    return RESTAURANT_ID;
  }

  private async getAuthToken(): Promise<string | null> {
    const { value: token } = await Preferences.get({ key: 'authToken' });
    return token;
  }

  async createPaymentIntent(cartItems: CartItem[], isDelivery: boolean, deliveryInfo?: DeliveryInfo, scheduledDate?: string): Promise<PaymentIntentResponse> {
    const paymentIntentURL = `${this.baseURL}/${this.restaurantId}/order-payment-intent`;
    const accessToken = await this.getAuthToken();

    if (!accessToken) {
      throw new Error('Unauthenticated');
    }

    const checkoutItems = cartItems.map(item => ({
      id: item.menuItem.id,
      name: item.menuItem.name,
      quantity: item.count
    }));

    const requestBody = {
      items: checkoutItems,
      is_delivery: isDelivery,
      delivery_info: deliveryInfo,
      scheduled_date: scheduledDate
    };

    const response = await fetch(paymentIntentURL, {
      method: 'POST',
      headers: {
        'DubuyEats-AppId': this.restaurantId,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error('Failed to create payment intent');
    }

    return response.json();
  }

  async completePayment(orderId: string, paymentIntentId: string): Promise<string> {
    const completePaymentURL = `${this.baseURL}/${this.restaurantId}/order/${orderId}/complete-payment`;
    const accessToken = await this.getAuthToken();

    if (!accessToken) {
      throw new Error('Unauthenticated');
    }

    const requestBody = {
      paymentIntentId: paymentIntentId
    };

    const response = await fetch(completePaymentURL, {
      method: 'POST',
      headers: {
        'DubuyEats-AppId': this.restaurantId,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error('Failed to complete payment');
    }

    const data = await response.json();
    return data.orderId;
  }
}

export default CheckoutService;