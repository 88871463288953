import axios, { AxiosError } from 'axios';
import { Preferences } from '@capacitor/preferences';

// TODO: Externalize these constants to a config file for build-time configuration
const BASE_URL = 'https://test.api.chickybells.dubuyeats.com';
const RESTAURANT_ID = 'chickybells.dev';
const TIME_ZONE = 'Asia/Dubai'; // Assuming UAE time zone

export interface User {
    id: string;
    email: string | null;
    mobile: string | null;
    name: string | null;
    addresses: any[]; // Replace 'any' with a proper interface for addresses if available
    appId: string;
}

export interface AuthResponse {
    access_token: string;
    refresh_token: string;
}

export interface UpdateNameResponse {
    status: boolean;
    message: string;
}

export interface ApiError {
    detail: {
        type: string;
        loc: string[];
        msg: string;
        input: any;
        url: string;
    }[];
}

class AuthService {
    private static instance: AuthService;
    private constructor() {}

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }
        return AuthService.instance;
    }

    private getHeaders(contentType: string = 'application/json') {
        return {
            'Content-Type': contentType,
            'DubuyEats-AppId': RESTAURANT_ID
        };
    }

    private async getAuthHeaders(): Promise<Record<string, string>> {
        const { value: token } = await Preferences.get({ key: 'authToken' });
        return {
            ...this.getHeaders(),
            'Authorization': `Bearer ${token}`
        };
    }

    public async login(email: string): Promise<void> {
        try {
            const url = `${BASE_URL}/auth/authenticate`;
            const formData = new URLSearchParams();
            formData.append('email', email);

            const response = await axios.post(url, formData, {
                headers: this.getHeaders('application/x-www-form-urlencoded')
            });

            if (response.status === 200) {
                await Preferences.set({ key: 'userEmail', value: email });
            } else {
                throw new Error('Authentication failed');
            }
        } catch (error) {
            this.handleApiError(error);
        }
    }

    public async verifyOTP(otp: string): Promise<void> {
        try {
            const { value: email } = await Preferences.get({ key: 'userEmail' });
            if (!email) throw new Error('Email not found');

            const url = `${BASE_URL}/auth/verify-code`;
            const formData = new URLSearchParams();
            formData.append('email', email);
            formData.append('code', otp);

            const response = await axios.post(url, formData, {
                headers: this.getHeaders('application/x-www-form-urlencoded')
            });

            if (response.status === 200) {
                const data: AuthResponse = response.data;
                await Preferences.set({ key: 'authToken', value: data.access_token });
                await Preferences.set({ key: 'refreshToken', value: data.refresh_token });
            } else {
                throw new Error('OTP verification failed');
            }
        } catch (error) {
            this.handleApiError(error);
        }
    }

    public async logout(): Promise<void> {
        await Preferences.remove({ key: 'authToken' });
        await Preferences.remove({ key: 'refreshToken' });
        await Preferences.remove({ key: 'userEmail' });
    }

    public async fetchUserInfo(): Promise<User> {
        try {
            const url = `${BASE_URL}/user/me`;
            const response = await axios.get(url, { headers: await this.getAuthHeaders() });
            return response.data;
        } catch (error) {
            this.handleApiError(error);
            throw error; // Re-throw after logging
        }
    }

    public async updateUserName(name: string): Promise<UpdateNameResponse> {
        try {
            const url = `${BASE_URL}/user/name`;
            const response = await axios.post(url, { name }, { headers: await this.getAuthHeaders() });
            return response.data;
        } catch (error) {
            this.handleApiError(error);
            throw error; // Re-throw after logging
        }
    }

    public async refreshToken(): Promise<void> {
        try {
            const { value: refreshToken } = await Preferences.get({ key: 'refreshToken' });
            if (!refreshToken) throw new Error('No refresh token available');

            const url = `${BASE_URL}/auth/token`;
            const response = await axios.post(url, { refresh_token: refreshToken }, { headers: this.getHeaders() });

            if (response.status === 200) {
                const data: AuthResponse = response.data;
                await Preferences.set({ key: 'authToken', value: data.access_token });
                await Preferences.set({ key: 'refreshToken', value: data.refresh_token });
            } else {
                throw new Error('Token refresh failed');
            }
        } catch (error) {
            this.handleApiError(error);
            throw error; // Re-throw after logging
        }
    }

    private handleApiError(error: unknown): void {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError<ApiError>;
            if (axiosError.response?.data?.detail) {
                console.error('API Error:', axiosError.response.data.detail);
                // You can handle specific error types here
                const errorDetail = axiosError.response.data.detail[0];
                throw new Error(`${errorDetail.msg} for ${errorDetail.loc.join('.')}`);
            } else {
                console.error('Axios error:', axiosError.message);
                throw new Error('An error occurred while communicating with the server');
            }
        } else {
            console.error('Unexpected error:', error);
            throw new Error('An unexpected error occurred');
        }
    }
}

export const authService = AuthService.getInstance();