import React, { useState } from 'react';
import { IonContent, IonPage, IonInput, IonButton, IonText, IonImg, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useAuth } from '../contexts/AuthContext';
import './LoginView.css';

interface LoginViewProps {
  onDismiss: (data?: any, role?: string) => void;
}

const LoginView: React.FC<LoginViewProps> = ({ onDismiss }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { login } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      await login(email);
      onDismiss(undefined, 'confirm');
    } catch (error) {
      console.error('Login error:', error);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    }
  };

  return (
    <IonPage>
      <IonContent className="login-view">
        <IonIcon icon={closeOutline} className="close-button" onClick={() => onDismiss(null, 'cancel')} />
        <IonImg src="/assets/chicky-bells-logo.png" alt="Chicky Bells Logo" className="logo" />
        <h1>Sign In</h1>
        <IonText className="subtitle">Hi, Enter your email</IonText>
        <form onSubmit={handleSubmit}>
          <IonInput
            type="email"
            value={email}
            onIonChange={(e: CustomEvent) => setEmail(e.detail.value!)}
            placeholder="Enter email"
            required
          />
          {error && <IonText color="danger">{error}</IonText>}
          <IonButton expand="block" type="submit" disabled={!email.trim()}>Verify</IonButton>
        </form>
        <IonText className="terms">
          By continuing, you agree to the Account terms and acknowledge Privacy Policy.
        </IonText>
      </IonContent>
    </IonPage>
  );
};

export default LoginView;