import React from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonFooter, useIonModal } from '@ionic/react';
import { close, trash, add, remove } from 'ionicons/icons';
import { useCartManager } from '../contexts/CartManagerContext';
import { CartItem } from '../contexts/CartManagerContext'; 
import OptimizedImage from '../components/OptimizedImage';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import LoginView from './LoginView';
import OTPVerificationView from './OTPVerificationView';
import CheckoutView from './CheckoutView';
import './CartView.css';

interface CartItemRowProps {
  item: CartItem;
  onRemove: (id: string) => void;
  onUpdateQuantity: (id: string, newQuantity: number) => void;
}

const CartItemRow: React.FC<CartItemRowProps> = ({ item, onRemove, onUpdateQuantity }) => {
  const getNumericPrice = (price: string | number): number => {
    return typeof price === 'string' ? parseFloat(price) : price;
  };

  const totalPrice = getNumericPrice(item.menuItem.price) * item.count;

  return (
    <div className="cart-item-row">
      <div className="item-details">
        <h2>{item.menuItem.name}</h2>
        <div className="item-actions">
          <IonButton 
            fill="clear" 
            className="remove-button"
            onClick={() => onRemove(item.menuItem.id)}
          >
            <IonIcon icon={trash} />
          </IonButton>
          <div className="quantity-control">
            <IonButton 
              fill="clear" 
              className="quantity-button"
              onClick={() => onUpdateQuantity(item.menuItem.id, item.count - 1)}
              disabled={item.count === 1}
            >
              <IonIcon icon={remove} />
            </IonButton>
            <span className="quantity">{item.count}</span>
            <IonButton 
              fill="clear" 
              className="quantity-button"
              onClick={() => onUpdateQuantity(item.menuItem.id, item.count + 1)}
            >
              <IonIcon icon={add} />
            </IonButton>
          </div>
        </div>
      </div>
      <div className="item-image-price">
        {item.menuItem.image_url && (
          <div className="item-image-wrapper">
                    <OptimizedImage 
                        src={item.menuItem.image_url} 
                        alt={item.menuItem.name}
                        className="item-image"
                    />
                </div>
        )}
        <div className="item-price">
          {item.menuItem.currency} {totalPrice.toFixed(2)}
        </div>
      </div>
    </div>
  );
};

const CartView: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { cart, removeFromCart, updateItemQuantity, getCartTotal } = useCartManager();
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  const handleRemoveItem = (itemId: string) => {
    removeFromCart(itemId);
  };
  
  const [presentLogin, dismissLogin] = useIonModal(LoginView, {
    onDismiss: (data: string, role: string) => {
      dismissLogin(data, role);
      if (role === 'confirm') {
        presentOTP();
      }
    },
  });

  const [presentOTP, dismissOTP] = useIonModal(OTPVerificationView, {
    onDismiss: (data: string, role: string) => dismissOTP(data, role),
  });

  const [presentCheckout, dismissCheckout] = useIonModal(CheckoutView, {
    onDismiss: (data: string, role: string) => dismissCheckout(data, role),
  });

  const handleUpdateQuantity = (itemId: string, newQuantity: number) => {
    updateItemQuantity(itemId, newQuantity);
  }

  const handleCheckout = () => {
    if (isAuthenticated) {
      // Proceed to checkout
      console.log('Proceeding to checkout');
      presentCheckout({
        cssClass: 'my-class',
      });

    } else {
      // Present login modal
      presentLogin({
        cssClass: 'my-class',
      });
    }
  };

  return (
    <IonPage className="cart-view">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Cart</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {cart.map((item) => (
          <CartItemRow
            key={item.menuItem.id}
            item={item}
            onRemove={handleRemoveItem}
            onUpdateQuantity={handleUpdateQuantity}
          />
        ))}
        <div className="subtotal">
          <span>Sub Total</span>
          <span>{cart[0]?.menuItem.currency} {getCartTotal().toFixed(2)}</span>
        </div>
      </IonContent>
      <IonFooter>
      <IonButton expand="block" onClick={handleCheckout}>Checkout</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default CartView;