import React, { useState } from 'react';
import { IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { close, add, remove } from 'ionicons/icons';
import { useCartManager } from '../contexts/CartManagerContext';
import { MenuItem } from '../services/menuApiService';
import './MenuItemDetailsView.css';

interface MenuItemDetailsViewProps {
  menuItem: MenuItem;
  onClose: () => void;
}

const MenuItemDetailsView: React.FC<MenuItemDetailsViewProps> = ({ menuItem, onClose }) => {
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCartManager();

  const handleAddToCart = () => {
    addToCart({ menuItem, count: quantity });
    onClose();
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>{menuItem.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="menu-item-details-view">
        <div className="menu-item-image" style={{ backgroundImage: `url(${menuItem.image_url})` }} />
        <div className="menu-item-content">
          <h1>{menuItem.name}</h1>
          <h2>{menuItem.currency} {menuItem.price}</h2>
          <p>{menuItem.description}</p>
          <div className="quantity-selector">
            <IonButton fill="clear" onClick={() => setQuantity(q => Math.max(1, q - 1))}>
              <IonIcon icon={remove} />
            </IonButton>
            <span>{quantity}</span>
            <IonButton fill="clear" onClick={() => setQuantity(q => q + 1)}>
              <IonIcon icon={add} />
            </IonButton>
          </div>
        </div>
        <IonButton expand="block" className="add-to-cart-button" onClick={handleAddToCart}>
          Add to Cart
        </IonButton>
      </IonContent>
    </>
  );
};

export default MenuItemDetailsView;