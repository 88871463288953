// utils/urlTransformer.ts

export const transformToCdnUrl = (storageUrl: string): string => {
  try {
    const url = new URL(storageUrl);
    
    // Split the hostname into parts
    const hostParts = url.hostname.split('.');
    
    // Find the index of the region part (e.g., 'ams3')
    const regionIndex = hostParts.findIndex(part => part.match(/^[a-z]+\d+$/));
    
    if (regionIndex !== -1) {
      // Insert 'cdn' after the region part
      hostParts.splice(regionIndex + 1, 0, 'cdn');
      
      // Reconstruct the hostname
      const cdnHostname = hostParts.join('.');
      
      // Construct the new URL with the CDN hostname
      url.hostname = cdnHostname;
      
      return url.toString();
    } else {
      // If we can't find the region part, return the original URL
      return storageUrl;
    }
  } catch (error) {
    console.error('Error transforming URL:', error);
    return storageUrl; // Return the original URL if there's an error
  }
};